.container {
    width: 100%;
    position: relative;
    overflow-x: hidden;
}

.cardContainer {
    display: flex;
    width: 100%;
    overflow-x:auto;
    align-items: center;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    gap: 15px;
    padding-right: 15px;
}

/* .cardContainer > *:last-child {
    margin-right: 50px;  
} */

.detailContainer {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
    overflow-y: auto;
    transition: left 0.5s ease;
}

.showDetail {
    left: 0;
}

.cardContainer > * {
    scroll-snap-align: center; /* Snap hvert barn til sentrum */
}