.cardContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    justify-content: space-between;
    width: 100%;
    /* background-position: center;
    background-size: cover;
    background-image: url(../../../images/klienter.webp);
    background-color: rgba(255, 255, 255, 0.4);
    background-blend-mode: overlay; */
    padding-left: 2%;
    padding-right: 2%;
    /* background-color: lightgray; */
    scrollbar-width: none; /* Fjerner scrollbar i Firefox */
    -ms-overflow-style: none;
}
.cardContainer::-webkit-scrollbar {
    display: none;
}

.card {
    min-width: 55vw;
    max-width: 55svw;
    height: auto;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.2);
    margin-top: 1.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px 0px 20px 0px;
    background-color: #FFF;
    justify-content: space-evenly;
    margin-right: 5vw;
    overflow-x: hidden;
    padding-top: 3%;
    border: 2px solid transparent;
    transition: 0.3s ease border-color;
    margin-bottom: 1.5vh;
    background-color: rgba(255, 255, 255, 0.95);
}

.card:hover {
    border-radius: 0px 20px 0px 20px;
    cursor: pointer;
    border-color: #FFB34B;
}

.profilePictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 5%;
}

.clientProfilePictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 5%;
}

.profilePicture {
    object-fit: cover;
    width: 30%;
    height:30%;
    border-radius: 50%;
    background-color: #FFF;
}

.modal {
    margin-top: 15%;
    padding-bottom: 25%;
}
.clientProfilePicture {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

.name{
    font-size: 1.2em;
    text-align: center;
    font-weight: 700;
    margin-top: 3%;
    width: 100%;
    margin: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.infoDiv {
    height:40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2%;
}

.info {
    width:95%;
    word-wrap: break-word;
    font-size: 0.9em;
    margin:auto;
    text-align: center;
    overflow-x: hidden;
    font-style: italic;
    font-weight: 600;
}

.modalImageContainer {
    width:100%;
}

.closeButton{
    padding-left: 5%;
    padding-right: 5%;
    background-color: lightcoral;
    border-radius: 10px;
}

.closeButton:hover {
    background-color: #000;
    cursor: pointer;
    color:lightcoral;
}

@media (min-width: 720px) {
    
    .imageContainer{
        width: 10vh;
        height: 10vh;
    }

    .cardContainer {
        flex-wrap: wrap;
    }

    .card {
        min-width: 25%;
        max-width: 25%;
        height: 40vh;
        max-height:400px;
        padding: 2%;
        margin-right: 0;
    }

    .info {
        font-size: 1em;
    }

    .container2 {
        display: flex;
    }

    .communicationsContainer {
        width: 30%;
    }
}

@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    .card {
        height: 21vh; /* Juster til en mer proporsjonal høyde */
    }
}

@media(min-width: 1024px) {

    .cardContainer{
        border-radius: 20px;
        /* box-shadow: 2px 2px 10px rgba(0,0,0,0.2); */
        scrollbar-width: none;
        -ms-overflow-style: none;
        flex-wrap: nowrap;
    }

    .cardContainer::-webkit-scrollbar {
        display:none;
    }

    .card{
        padding: 1%;
        margin-right: 5%;
        overflow: hidden;
        max-height: 400px;
    }

    .infoDiv {
        height:20%;
        overflow: hidden;
    }

    .info {
        overflow: hidden;
    }

    .modalImageContainer {
        width:20%;
        margin-right: 5%;
    }

    .modalBody{
        display:flex;
        flex-direction: row;
        align-items: center;
    }
}