.button, .button2 {
    border-radius: 15px;
    padding: 5px 15px;
    border: 1px solid transparent;
    background-color: #FFB34B;
    color:#000;
}

.button:hover {
    background-color: #000;
    color: #FFB34B;
}

.button2 {
    background-color: #000;
    color: #FFF;
}

.container{
    margin-top: 50%;
}

@media (min-width: 720px) {
    .container {
        margin-top: 0;
    }
}