@import '../../../styles/variables.scss';

.showMoreButton, .showLessButton {
    background-color: rgba(0,0,0,0.35);
    border-radius: 10px;
    color:#FFF;
    border:2px solid transparent;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    @media (min-width: 768px) {
        transition: transform 0.3s ease, border-color 0.3s ease;
        &:hover {
            transform: scale(1.05);
            cursor: pointer;
            border-color: $primary;
        }
    }
}

.listItem {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.listItem.visible {
    opacity: 1;
    transform: translateY(0);
}

.overskrift {
    font-size: 2rem;
}

.navnOverskrift {
    font-weight: 600;
    font-size: 1.8rem;
}

.profilePictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 5%;
}

.clientProfilePictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 5%;
}

.profilePicture {
    object-fit: cover;
    width: 30%;
    height:30%;
    border-radius: 50%;
    background-color: #FFF;
}

.clientProfilePicture {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}