.clientList {
    width: 100%;
    margin-top: 20px;
    padding: 0px;
    gap: 15px;
    display: flex;
    flex-direction: column;
}

.container {
    width:100%;
}

.addClientContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select {
    border-radius: 20px;
    border: 1px solid gray;
    padding: 5px 25px;
}

.addClientButton {
    border: none;
    color:#000;
    border-radius: 20px;
    padding: 5px 25px;
    background-color: lightgreen;
}