@import "./variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

$font-family-base: "yaldevi", sans-serif;
$font-weight: 400;
$font-style: normal;

// .body {
//     overflow-y: auto;
// }

.body::-webkit-scrollbar {
    display: none;
}

/* Skjul scrollbar i Firefox */
.body {
    scrollbar-width: none; /* Skjuler scrollbar i Firefox */
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }
}

.appearing-container {
    opacity:0;
    animation: fadeInUp 0.5s ease forwards;
}

// @media (min-width: 1000px) {
//     body {
//         overflow-y: hidden;
//     }
// }