.test {
    height:70vh;
}

.buttonRow {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

.viewButton, .markReadButton {
    border:none;
    border-radius: 20px;
    text-align: center;
    padding: 10px 20px;
}

.viewButton {
    background-color: lightgreen;
}

.markReadButton {
    background-color: skyblue;
}

.notificationItem {
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
    margin-top: 10px;
}

.notificationList {
    border-top: 1px solid lightgray;
}

.notificationsWrapper {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}