.clientList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.clientCard {
    display: flex;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.25);
    padding: 10px;
    width: 100%;
    justify-content: space-between;
}

.profilePicture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    background-position: center;
    background-size: cover;
}

.clientInfo{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.clientInfo h4 {
    margin: 0;
    font-size: 1em;
}

.clientInfo p {
    margin: 0;
    font-size: 0.9em;
    color: #666;
}

.cancelButton, .submitButton, .removeIngredientButton, .addIngredientButton, .deleteVariantButton, .updateVariantButton {
    color: #000;
    border: none;
    padding: 5px 20px;
    border-radius: 20px;
    background-color: lightcoral;
}

.submitButton, .addIngredientButton, .updateVariantButton {
    background-color: lightgreen;
}

.updateVariantButton:disabled, .submitButton:disabled {

    cursor:not-allowed;
    color: gray;
}

.ingredientInput, .ingredientSearchInput {
    border-radius: 20px;
    border: 1px solid gray;
    padding-left: 5%;
    width:20%;
}

.ingredientSearchInput {

    width: 100%;
}

.addIngredientDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 20px;
}

.ingredientAmountInput {
    border-radius: 20px;
    border: 1px solid gray;
    padding: 5px 20px;
}

.row {
    display: flex;
    width: 80%;
    align-items: center;
    gap: 10px;
}

.ingredientOption {
    box-shadow: 0px 0px 20px rgba(0,0,0,0.25);
    border-radius: 20px;
    padding: 5px 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.filteredIngredients {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.container2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.ingredientAdjustContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.macroSummary {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.modalCustomFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-width:768px) {
    .clientCard{
        transition: 0.3s ease background-color;
    }

    .clientCard:hover {
        background-color: rgba(0,0,0,0.15);
        cursor: pointer;
    }

    .ingredientOption {
        width:100%;
        padding: 5px 25px;
        margin-top: 10px;
        margin-bottom: 0;
        transition: 0.3s ease background-color;
    }

    .ingredientOption:hover {
        cursor: pointer;
        background-color: rgba(0,0,0,0.15);
    }
}