.container {
    margin-top: 10%;
    padding-bottom: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 3;
    overflow-y: auto;
}

.openButton, .closeButton {
    background-color: #E4E4E4;
    border-radius: 10px;
    color: #000;
    box-shadow: 2px 2px 5px rgba(0,0,0, 0.5);
}
.openButton:hover, .closeButton:hover {
    background-color: #000;
    color: #FFB34B;
    cursor: pointer;
}

.openButton {
    width: 100%;
    text-align: center;
    font-size: 1.2em;
}

.modalBody {
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    position:fixed;
    background-color: rgba(0,0,0, 0.5);
    z-index:9999;
    display: flex;
    flex-direction: column;
}

.modalContentBackground {
    margin-top: 4vh;
    background-color: #FFF;
    min-height: 80vh;
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    padding:10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.button {
    border-radius: 20px;
    padding-left: 3%;
    padding-right: 3%;
    border: 1px solid transparent;
    background-color: #E4E4E4;
    color:#000;
}

.button:hover {
    border-color: #000;
    cursor: pointer;
}

@media (min-width: 1024px) {
    .container{
        margin-top: 0;
        padding-bottom: 0;
    }
}