.container {
    background-color: #FFB34B;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.3em;
    padding-top: 2%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subH1 {
    font-size: 1.4em;
    font-weight: 600;
}

.dropdownContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.label {
    margin-bottom: 1.5%;
}

.select {
    border-radius: 10px;
    padding-left: 2%;
    padding-right: 2%;
    border: none;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    border-radius: 10px;
    border: 1px solid transparent;
    font-size: 1em;
    width: 70%;
    margin-top: 3vh;
    background-color: #FFF;
    color:#000;
    margin-bottom: 5vh;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.input {
    border-radius: 10px;
    border: none;
    padding-left: 2%;
    padding-right: 2%;
}

.button:hover {
    background-color: #000;
    color: #FFF;
}