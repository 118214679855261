.selectedClient {
    display: flex;
    width: 60%;
    background-color: #FFB34B;
    padding-left: 3%;
    padding-right: 3%;
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;
    margin-top: 5%;
}

.deleteButton {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: #FFF;
    padding: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #C4C4C4;
    font-weight: 800;
}

.deleteButton:hover {
    cursor: pointer;
    background-color: #E4E4E4;
    color: #000;
}

.list {
    list-style-type: none;
}

.submitButton {
    background-color: #FFB34B;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: auto;
    padding: 1%;
    border: 1px solid transparent;
}
.submitDiv {
    margin-top: 7%;
}
.submitButton:hover {
    background-color: #FFF;
    color:#000;
    cursor: pointer;
    border-color: #FFB34B;
}

@media (min-width: 720px) {
    .submitButton {
        width: 30%;
    }

    .selectedClient {
        width: 50%;
    }
}