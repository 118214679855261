.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.CardList {
    border-radius: 15px;
    padding-bottom: 4%; 
    padding-top: 2%;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 2px 15px rgba(0,0,0,0.1);
    margin-top: 4%;
    padding-left: 0;
    padding-right: 0;
    background-color: lightgray;
    width: 95%;
}

.li {
    margin-top: 3%;
    width: 90%;
    height:100%;
    margin-left: auto;
    margin-right: auto;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 2px solid transparent;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
    text-overflow: ellipsis;
    background-color: #FFF;
}

.li:hover{
    text-decoration: underline;
    cursor: pointer;
    border-color: #313131;
}

.closed {
    display:none;
}

.dropdownOpen {
    display: block;
    width:100%;
}

.input{
    border-radius: 20px;
    border: 1px solid transparent;
    box-shadow:0px 2px 15px rgba(0, 0, 0, 0.1); 
    margin-top: 1em;
    /* width: 90%; */
    width: 70vw;
    padding: 5px 10px;
}
.userInput {
    border-radius: 20px;
    border: 1px solid transparent;
    /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1); */
    margin-top: 1em;
    padding: 5px 10px;
    width: 50%;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
}
.container2 {
    width: 70vw;
    margin: auto;
}

.imageContainer {
    height: 10em;
    margin-top: 1em;
    width: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
}

.nameDiv {
    margin-top: 2em;
    margin-bottom: 1em;
}

.userList {
    background-color: #FFB34B;
    padding-left:0;
    padding-right: 0;
    padding-top: 2%;
    padding-bottom: 4%;
    margin-top: 1%;
    border-radius: 20px;
}

@media (min-width: 720px) {

    .userInput {
        width: 25vw;
    }

    .container2 {
        width: 25vw;
    }

    .input {
        width: 70%;
    }
}