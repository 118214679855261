@import '../../../../../styles/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:20px;
}

.header {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
}

.addButton {
    padding: 5px 15px;
    border-radius: 20px;
    background-color: $primary;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.createButton, .addIngredientButton {
    padding: 5px 15px;
    border:none;
    border-radius: 20px;
    background-color: lightgreen;
    text-align: center;
    width:auto;
}

.ingredientOption {
    border-radius: 20px;
    background-color: rgba(0,0,0,0.05);
    padding: 5px 25px;
    margin-top: 8px;
    transition: 0.3s ease background-color;

    @media (min-width: 768px) {
        &:hover {
            cursor: pointer;
            background-color: rgba(0,0,0,0.15);
        }
    }
}

.listContainer {
    display: flex;
    align-items: center;
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-top: 20px;
    padding-bottom: 20px;
}

.modalContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.addIngredientContainer, .filteredIngredients, .nameInput {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ingredientAdjustContainer {
    display: flex;
    align-items: center;
    width: 85%;
    justify-content: space-between;
    margin-top: 2%;
}

.ingredientInput, .ingredientAmountInput {
    border-radius: 20px;
    border: 1px solid gray;
    width: 25%;
    padding: 3px 20px;
    text-align: center;
}

.ingredientAmountInput {
    width: 70%;
}

.cancelButton {
    border-radius: 20px;
    border:none;
    background-color: lightcoral;
    padding: 5px 25px;
}

.buttonGroup2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
    margin-bottom: 20px;
}

.addIngredientDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.removeIngredientButton {
    border-radius: 20px;
    border:none;
    padding: 5px 20px;
    background-color: lightcoral;
}

.ingredientSearchInput {
    border-radius: 20px;
    border: 1px solid gray;
    margin-bottom: 5%;
    padding: 3px 10px;
}

.selected {
    background-color: lightgreen;
    border-radius:20px;
    padding: 5px 20px;
}

.modal {
    margin-top: 20%;
    padding-bottom: 35%;

    @media(min-width: 768px) {
        margin: none !important;
        padding: none !important;
    }
}