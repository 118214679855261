.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 2%;
    padding-bottom: 10%;
}

.input, .inputRating, .weightInput {
    width:85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputInput, .weightInputfield {
    border-radius: 20px;
    border: 2px solid transparent;
    box-shadow: 2px 2px 5px rgba(0,0,0, 0.2);
    transition: 0.3s ease border-color;
    padding:5px 20px;
    width: 100%;
}

.weightInputfield {
    width: 40%;
    margin-left: 10%;
}

.inputInput:hover{
    border-color: #FFB34B;
    cursor: pointer;
}

.imageInput{
    flex-direction: column;
    width:50%;
    margin-top: 2%;
    margin-bottom: 5%;
}

.inputRating{
    align-items: center;
}

.submitButton {
    border-radius: 20px;
    background-color: #FFB34B;
    padding: 1% 3%;
    width: 55%;
    border:none;
    box-shadow: 2px 2px 5px rgba(0,0,0, 0.2);
    text-align: center;
    color: #000;
}

.stars {
    display: flex;
    justify-content: space-between;
}

.star {
    font-size: 2rem;
    cursor: pointer;
}

.star.filled {
    color: gold;
}

.container {
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}