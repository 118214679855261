.overskrift {
    margin-top: 2vh;
    font-size: 2em;
    font-weight: 800;
    padding-left: 1%;
}

.form {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.uploadProfileImageConntainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notContainer {
    width: 97%;
    margin: auto;
}

.formButton {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFB34B;
    text-align: center;
    border-radius: 20px;
    margin-top: 5%;
    margin-bottom: 10%;
}

.rating {
    display: flex;
    font-size: 2em;
    align-items: center;
}

.starFilled {
    color: gold;
    margin-right: 5px;
}

.starEmpty {
    color: lightgray;
    margin-right: 5px;
}

.clientCheckInImage {
    width: 50px;
    height: 50px;
}

.cardContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Legger til horisontal rulling */
    white-space: nowrap; /* Forhindrer at kortene bryter til ny linje */
    justify-content: space-between;
    width: 100%;
    /* background-position: center;
    background-size: cover;
    background-image: url(../../../images/klienter.webp);
    background-color: rgba(255, 255, 255, 0.4);
    background-blend-mode: overlay; */
    padding-left: 2%;
    padding-right: 2%;
    background-color: lightgray;
}

.card {
    min-width: 55vw;
    max-width: 55svw;
    height: 35vh;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    margin-top: 1.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px 0px 20px 0px;
    background-color: #FFF;
    justify-content: space-evenly;
    margin-right: 5vw;
    overflow-x: hidden;
    padding-top: 3%;
    border: 2px solid transparent;
    transition: 0.3s ease border-color;
    margin-bottom: 1.5vh;
    background-color: rgba(255, 255, 255, 0.95);
}

.about {
    width: 97%;
    background-color: lightgray;
    margin: auto;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    padding:2%;
}
.container2 {
    margin-top: 5%;
}

.card:hover {
    border-radius: 0px 20px 0px 20px;
    cursor: pointer;
    border-color: #FFB34B;
}

.imageContainer {
    width: 12vh;
    height: 12vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-top: 1%;
    margin-bottom: 2%;
}

.profilePictureContainer {
    aspect-ratio: 1 / 1;
    width: 150px; /* Set a width that works for your design */
    height: auto; /* Maintains aspect ratio based on width */
    overflow: hidden; /* Ensures image fits within the circle */
    border-radius: 50%; /* Makes the container round */
    display: flex; /* Centers the image */
    align-items: center;
    justify-content: center;
}

.clientProfilePictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 5%;
}

.profilePicture {
    object-fit: cover;
    width: 100%;
    height:100%;
    border-radius: 50%;
    background-color: #FFF;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.35);
}

.clientProfilePicture {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

.name{
    font-size: 1.2em;
    text-align: center;
    font-weight: 700;
    margin-top: 3%;
    width: 100%;
    margin: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.infoDiv {
    height:40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2%;
}

.info {
    width:95%;
    word-wrap: break-word;
    font-size: 0.9em;
    margin:auto;
    text-align: center;
    overflow-x: hidden;
    font-style: italic;
    font-weight: 600;
}

.communicationsContainer {
    margin-top: 5vh;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 97%;
    margin: auto;
}

.underoverskrift, .underoverskrift2{
    font-size: 1.8em;
    font-weight: 600;
}

.underoverskrift2 {
    padding-left: 2%;
}

.editButton {
    border-radius: 20px;
    background-color: #FFB34B;
    width: 40%;
    text-align: center;
    margin-bottom:2%;
}

.editButton:hover {
    cursor: pointer;
    text-decoration: underline;
}

.area {
    border-radius: 5px;
    padding: 2%;
    width: 100%;
    border: none;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
}

.matplansContainer{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 2%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(../../../images/kosthold.avif);
    background-color: rgba(255, 255, 255, 0.25);
    background-blend-mode: overlay;
}

.matplanCard{
    width: 50%;
    margin: 2%;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow:hidden;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    background-color: rgba(255, 255, 255, 0.95);
}

.matplanImageContainer{
    margin-top: 2%;
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: center;
}

.sub2 {
    background-color: #FFF;
    padding-bottom: 3%;
}
.programsContainer{
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 2%;
    scrollbar-width: none; /* Fjerner scrollbar i Firefox */
    -ms-overflow-style: none;
    padding-left: 1%;
}
.programsContainer::-webkit-scrollbar {
    display: none;
}

.sub4, .sub2, .sub3 {
    width:97%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.2);
    border-radius: 20px;
    padding-top: 3%;
    padding-left: 1%;
    padding-right: 1%;
}

.sub3{
    margin-bottom: 2%;
}

.programs{
    min-width: 45%;
    max-width:45%;
    height: 20vh;
    border-radius: 20px;
    background-color: #f2f2f2;
    text-align: center;
    padding-bottom: 3%;
    margin-top: 2%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
}

.programImage{
    border-radius: 20px 20px 0 0;
    height:80%;
}

.sub3Header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addCoachButton, .closeButton {
    margin-right: 3%;
    background-color: #FFB34B;
    padding: 5px 15px;
    border-radius: 20px;
}

.modal {
    margin-top: 15%;
    padding-bottom: 25%;
}

.closeButton {
    background-color: lightcoral;
}

.closeButton:hover {
    background-color: #000;
    color: lightcoral;
}

.addCoachButton:hover {
    background-color: #000;
    color: #FFB34B;
}

.closeButton:hover, .addCoachButton:hover{
    cursor: pointer;
}

@media (min-width: 720px) {
    
    .imageContainer{
        width: 10vh;
        height: 10vh;
    }

    .cardContainer {
        flex-wrap: wrap;
    }

    .card {
        min-width: 25%;
        max-width: 25%;
        height: 40vh;
        max-height:400px;
        padding: 2%;
        margin-right: 0;
    }

    .info {
        font-size: 1em;
    }

    .container2 {
        display: flex;
    }

    .communicationsContainer {
        width: 30%;
    }
}

@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    .card {
        height: 21vh; /* Juster til en mer proporsjonal høyde */
    }

    .sub2 {
        background-color: transparent;
    }
}

@media (min-width: 1000px) {
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding-top: 3%;
    }

    .header {
        width: 100%;
        /* background-color: #e4e4e4; */
        border-radius: 20px;
        margin: 0;
        /* box-shadow: 2px 2px 10px rgba(0,0,0,0.2); */
    }

    .imageContainer{
        width: 100%;
        height:100%;
    }

    .profilePicture{
        height: 70%;
        width:70%;
        box-shadow: 0px 0px 20px rgba(0,0,0,0.25);
        /* background-color: #FFB34B; */
    }

    .about {
        width: 100%;
        border-radius: 20px;
    }

    .container4 {
        display: flex;
        flex-direction: column;
        width: 30%;
    }

    .sub1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-top: 5%;
    }

    .container2 {
        display: flex;
        flex-direction: column;
        width:65%;
        background-color: transparent;
        margin-top: 0;
    }

    .overskrift{
        text-align: center;
    }

    .cardContainer{
        border-radius: 20px;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
        scrollbar-width: none;
        -ms-overflow-style: none;
        flex-wrap: nowrap;
    }

    .cardContainer::-webkit-scrollbar {
        display:none;
    }

    .sub2, .sub3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        background-color: transparent;
        box-shadow: 0px 0px 30px rgba(0,0,0,0.25);
        border-radius: 20px;
        padding-top: 2%;
    }

    .sub3{
        margin-top: 5%;
    }

    .sub2::-webkit-scrollbar {
        display: none; /* Fjerner scrollbar i Chrome, Safari og Opera */
    }

    .subH {
        text-align: center;
        font-size: 2em;
        font-weight: 600;
    }

    .programsContainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
    }

    .programImage{
        display: none;
    }

    .programs {
        min-width: 30%;
        max-width: 30%;
        background-color: #fff;
        border-radius: 20px;
        font-size: 1.3em;
        font-style: italic;
        height: 2.5em;
        border: 2px solid transparent;
        transition: 0.3s ease border-color;
        box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
        padding-bottom: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .programName {
        height:100%;
    }

    .programs:hover {
        cursor: pointer;
        border-color:#FFB34B;
    }

    .container3 {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .matplansContainer{
        border-radius: 20px;
        min-height: 50vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .communicationsContainer{
        margin-left:auto;
        margin-right: auto;
        width:100%;
    }

    .profilePictureContainer{
        margin-top: 0;
    }

    .card{
        padding: 1%;
        margin-right: 5%;
        overflow: hidden;
        max-height: 400px;
    }

    .infoDiv {
        height:20%;
        overflow: hidden;
    }

    .sub5 {
        width: 100%;
    }

    .sub5 {
        display:flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        /* background-color: #faf0e6; */
    }

    .clientProfilePicture{
        width:90%;
    }

    .matplanCard {
        height: 4em;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 45%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
        padding: 1.5%;
        border-radius: 10px;
        overflow-x: hidden;
        transition: 0.3s ease border-color;
        border: 2px solid transparent;
    }

    .matplanCard:hover, .checkIn:hover {
        cursor: pointer;
        border-color: #FFB34B;
    }

    .matplanName{
        font-size: 1.5em;
        font-style: italic;
    }

    .sub7 {
        width: 100%;
        height: 53vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        /* background-image: url(../../../images/weigh-in.jpg); */
        /* background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(255, 255, 255, 0.6);
        background-blend-mode: overlay; */
        border-radius: 20px;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        background-color: lightgray;
        padding-bottom: 2%;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    }

    .checkIn{
        width: 85%;
        background-color: rgba(255,255,255, 0.95);
        text-align: center;
        border-radius: 20px;
        margin-top: 2%;
        transition: 0.3s ease border-color;
        border: 2px solid transparent;
        font-size: 1.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 2%;
        padding-right: 2%;
    }

    .info {
        overflow: hidden;
    }

    .sub2 {
        margin-top: 0;
    }
}