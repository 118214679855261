.openAccordionContainer1 {
    width: 100%;
    margin-top: 2vh;
    /* border-radius: 10px; */
    /* padding: 10px; */
    /* background-color: #E4E4E4;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.1); */
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: 13vh; */
    height:auto;
    padding-bottom: 2%;
    border-bottom: 1px solid lightgray;
}

.modalContent {
    width: 95%;
    margin: auto;
}

.header {
    height:300px;
    background-position: center;
    background-size: cover;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left: 3%;
    border-radius: 20px;
    padding-bottom: 5%;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.15);
    background-blend-mode: overlay;
}

.accordionHeaderContainer {
    width: 100%;
}

.accordionHeaderContainer:hover {
    cursor: pointer;
}

.overskrift {
    text-align: center;
    font-weight: 800;
    font-size: 2em;
    margin-top: 3vh;
}

.container1 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
}

.accordionContainer {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accordionContainer2 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.accordionCardImageContainer{
    width:45%;
    height:15vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* margin: auto; */
}

.exercise {
    margin-top: 2vh;
    font-size: 1.4em;
    font-weight: 600;
}

.exName {
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 0;
}

.setsReps, .comment {
    font-size: 0.8em;
    margin-top: 0;
    margin-bottom: 0;
}

.coachComment {
    font-size: 0.8em;
    margin: 0;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.editButton, .deleteButton, .editClients {
    background-color: #E4E4E4;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 20px;
    border: 2px solid transparent;
}

.editButton {
    background-color: #FFB34B;
}

.deleteButton {
    background-color: #F26060;
}

.editClients {
    width: 50%;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}

.editClients:hover {
    background-color: #FFF;
    text-decoration: underline;
    border-color: #FFB34B;
}

.editButton:hover {
    background-color: #FFF;
    color: #FFB34B;
    border-color:#FFB34B;
}

.deleteButton:hover {
    background-color: #FFF;
    color: red;
    border-color: red;

}

.editButton:hover, .deleteButton:hover, .editClients:hover {
    text-decoration: underline;
    cursor: pointer;
}

.search {
    width: 50%;
    margin: auto;
    border: none;
    padding: 2px 10px;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}

.startButton {
    border-radius: 20px;
    padding: 1% 3%;
    background-color: #FFB34B;
    width: 50%;
    text-align: center;
    font-weight: 600;
}

.exerciseCard {
    display:flex;
    flex-direction: column;
    background-color: #E4E4E4;
    margin-bottom: 3%;
    border-radius: 10px;
    padding-bottom: 2%;
}

.set {
    display: flex;
    width:100%;
    justify-content: space-around;
    align-items: center;
    height:100%;
    /* margin-left: 1%; */
    /* gap: 2%; */
    padding-left: 1%;
    padding-right: 1%;
}

.subHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 1%;
    padding-right: 1%;
    background-color: #FFB34B;
    margin-bottom: 1%;
}

.subReps {
    width:25%;
}

.input{
    width:25%;
    border:0;
    /* border-bottom: 1px solid darkgray; */
    border-bottom: 1px solid darkgray;
}
.presetReps{
    width:25%;
    text-align: center;
}
.setInput {
    margin-left: 3%;
    border-right: 1px solid darkgray;
}

.cardHeader{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    /* margin-bottom: 1%; */
    background-color: darkgray;
    border-radius: 10px 10px 0 0;
    height:20%;
}

.cardHeaderImageContainer{
    height:5em;
    width:30%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px 0 0 0;
}

.exerciseNameDiv {
    height:5em;
    width:65%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
}

.exerciseName {
    font-style: italic;
    margin-bottom: 0;
}

.checkbox {
    width: 1.5em;
    height: 1.5em;
    border-radius: 10px;
    border: 5px solid #F26060;
    margin-left: auto;
    margin-right: auto;
}

.checked {
    background-color: green;
    border:none;
}

.timerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
}
.timer {
    width: 50%;
    background-color: #E4E4E4;
    text-align: center;
    border-radius: 20px;
}
.clientsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
}
.programItem {
    display: flex;
    flex-direction: column;
    /* min-width: 70vw;
    max-width: 70vw; */
    width: 100%;
    height: 30vh;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #E4E4E4;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    font-style: italic;
    font-weight: 600;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.text{
    margin-bottom: 0;
    max-width: 80%;
    flex-wrap: wrap;
    text-align: center;
    font-size: 1.5em;
    font-weight: 600;
    font-style: italic;
    color:#FFF;
    padding:1% 2%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
}

.container1 {
    margin-bottom: 5%;
}

.programList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.programListItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.toggleButton {
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 20px;
    border:none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    background-color: #FFB34B;
}

.sortButtons {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.sortButtons button {
    margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    border-radius: 20px;
  }
  
  .sortButtons button.activeButton {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }

@media (min-width: 720px) {
    

    .accordionHeaderContainer {
        width: 30%;
    }

    .openAccordionContainer1 {
        width: 100%;
        margin-top: 0em;
        align-items: center;
    }

    .accordionContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
    }

    .accordionCards {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .accordionContainer2 {
        flex-direction: row;
        align-items: center;
        width: 70%;
        justify-content: space-between;
    }

    .accordionContainer3 {
        width: 30%;
    }

    .accordionContainer4 {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .accordionCardImageContainer {
        width: 25%;
    }

    .exName {
        word-wrap: break-word;
        width: 100%;
    }

    .editClients {
        width: 50%;
        text-align: center;
        margin-top: 4%;
    }

    .programItem {
        width: 30%;
        border: 2px solid transparent;
        transition: 0.3s ease border-color, 0.3s ease transform;
    }

    .programItem:hover {
        border-color: #FFB34B;
        cursor: pointer;
        transform: scale(1.05);
    }

    .search {
        width: 20%;
    }

    .toggleButton {
        width: 20%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    .programList {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between;
    }
    
    .programListItem {
        width: 23%;
        border: 2px solid transparent;
        transition: 0.3s ease border-color, 0.3s ease transform;
    }

    .programListItem:hover {
        border-color: #FFB34B;
        cursor: pointer;
        transform: scale(1.05);
    }
}