.container {
    display: flex;
    justify-content: space-between;
    max-width: 95%;
    margin: auto;
}

.calendarContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.dayContainer {
    text-align: center;
    width: 10%;
}

.workoutDay, .noWorkoutDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px; /* Juster størrelsen som passer deg */
    height: 35px; /* Pass på at høyden matcher bredden for perfekt sirkel */
    border-radius: 50%;
    padding: 10px;
    font-size: 0.8rem;
}

.workoutDay {
    border: 2px solid green;
}

.noWorkoutDay {
    border: 1px solid red;
}
