.coachCard{
    width: 75%;
    margin: auto;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.9);
}

.approveButton {
    background-color: lightgreen;
    border: 2px solid transparent;
    border-radius: 20px;
    padding: 5px 15px;
    transition: 0.3s ease border-color, 0.3s ease background-color;
}

.approveButton:hover {
    border-color: lightgreen;
    background-color: #000;
    color: lightgreen;
    cursor: pointer;
}

.denyButton {
    background-color: lightcoral;
    border: 2px solid transparent;
    border-radius: 20px;
    padding: 5px 15px;
    transition: 0.3s ease border-color, 0.3s ease background-color;
}

.denyButton:hover {
    border-color: lightcoral;
    background-color: #000;
    color: lightcoral;
    cursor: pointer;
}

.buttonDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 720px) {
    .coachCard {
        width: 60%;
    }
}