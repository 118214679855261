.container {
    background-color: #FFB34B;
    border-radius: 30px;
    padding-top: 2%;
    font-size: 1.3em;
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    margin-bottom: 5vh;
    align-items: center;
    width: 100%;
}

.input {
    border-radius: 10px;
    padding: 5px;
    border: none;
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 1em;
    width: 80%;
}

/* .buttonContainer {
    width: 50%;
} */

.button {
    border-radius: 10px;
    border: 1px solid transparent;
    font-size: 1em;
    width: 70%;
    margin-top: 3vh;
    background-color: #FFF;
    color:#000;
    margin-bottom: 5vh;
}

.button:hover {
    background-color: #000;
    color: #FFF;
}
.alertContainer {
    margin-top: 50%;
}

.error {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    text-align: center;
}

.link {
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 3%;
}

@media (min-width: 720px) {
    .alertContainer{
        margin:0;
    }
}