.mealItem, .instanceItem {
    padding: 10px;
    margin: 5px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: border 0.2s;
    &:hover{
        border-color: #aaa;
    }
  }
  
  .selectedMeal, .selectedInstance {
    border-color: #007bff; // eller en annen farge for å indikere valg
    background-color: rgba(0, 123, 255, 0.1);
  }

  .input, .textArea {
    border: 2px solid transparent;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    width:100%;
    margin-left: auto;
    margin-right: auto;
}

.form {
    gap: 15px;
    padding-bottom: 50px;
    position: relative;
    width:100%;
    overflow-x: hidden;
}

.selectedItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.selectedList {
    width:100%;
    gap:20px;
    display: flex;
    flex-direction: column;
}

.removeButton, .submitButton {
    background-color: lightcoral;
    padding: 5px 20px;
    border-radius: 20px;
    @media (min-width: 768px) {
        &:hover {
            cursor: pointer;
            background-color: #000;
            color:lightcoral;
        }
    }
}

.submitButton{
    background-color: lightgreen;
    border:none;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 600;
    @media (min-width: 768px) {
        &:hover {
            cursor: pointer;
            background-color: #000;
            color:lightgreen;
        }
    }
}

.wrapper {
  width: 100%;
  margin-top: 1rem;
  position: relative;
}

.searchContainer {
  margin-bottom: 1rem;
}

.searchContainer input {
  padding: 0.5rem;
  width: 250px;
  font-size: 1rem;
}

.categorySection {
  margin-bottom: 2rem;
  overflow-x: auto;
  width:100%;
  position: relative;
  scroll-snap-type: x mandatory;
  padding-left: 20px;
  gap:20px;
  padding-right: 20px;
}

.categoryTitle {
  margin: 1rem 0;
}

.horizontalScrollContainer {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
}

.mealCard {
  position: relative;
  min-width: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  height:200px;
  scroll-snap-align: center;
  flex-shrink: 0;
  cursor: pointer;
  transition: background-color 0.2s ease;

  @media (min-width: 768px) {
    &:hover{
        background-color: #e0f8e0;
    }
  }
}

.selected, .selectedClient {
  background-color: lightgreen /* lysegrønn for valgt */
}

.overlayCheck {
  position: absolute;
  top: 0;
  left: 0;
//   background: rgba(60, 179, 113, 0.9); /* litt grønn overlay */
  //color: #fff;
  //padding: 4px 8px;
  border-radius: inherit;
  font-weight: bold;
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark {
  font-size: 3rem;
  color: lightgreen
}

.instanceList {
  margin-top: 1rem;
  border-top: 1px dashed #ccc;
  padding-top: 0.5rem;
}

.instanceItem {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
  background-color: #eee;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.instSelected {
  background-color: lightgreen;
}

.mealCard {
    position: relative;
    background-size: cover;
    background-position: center;
    color: #fff;
    border-radius: 8px; // eksempel på avrundede hjørner
  
    // Bruk ::before for overlayet
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3); // sort overlay med 50% transparens
      border-radius: inherit; // arver border-radius fra .mealCard
      z-index: 0; // ligger bak innholdet
    }
  
    // Sørg for at direkte barn (som knapper, tekst osv.) ligger over overlayet
    > * {
      position: relative;
      z-index: 1;
    }
  }

.lasteTekst {
    font-size: 2rem;
    margin-bottom: 50vh;
}
  