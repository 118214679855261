.programItem {
    display: flex;
    flex-direction: column;
    /* min-width: 70vw;
    max-width: 70vw; */
    /* width: 100%; */
    min-width: 350px;
    height: 30vh;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #E4E4E4;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    font-style: italic;
    font-weight: 600;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.programList {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    display: flex;
    gap:20px;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
}

.programList::-webkit-scrollbar {
  display: none;
}

.programList > * {
  scroll-snap-align: center; /* Snap hvert barn til sentrum */
}

.programList > *:last-child {
  margin-right: 50px;  
}

.programList > *:first-child {
  margin-left: 50px;
}

.text{
    margin-bottom: 0;
    max-width: 80%;
    flex-wrap: wrap;
    text-align: center;
    font-size: 1.5em;
    font-weight: 600;
    font-style: italic;
    color:#FFF;
    padding:1% 2%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
}

.clientCommentBox {
    border-radius: 20px;
    border: 1px solid gray;
    padding: 5px 15px;
    width: 100%;
    margin: auto;
}

.programHeader{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width:100%;
    height:70%;
    border-radius: 10px 10px 0 0;
}

.programsDivInner, .workoutsDivInner {
    display: flex;
    overflow-x: auto;
    gap: 3%;
    padding-left: 2%;
    padding-right: 2%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.programsDivInner::-webkit-scrollbar, .checkInCardsContainer::-webkit-scrollbar, .workoutsDivInner::-webkit-scrollbar {
    display: none;
}

.programsDiv:hover {
    cursor:pointer;
}

.container1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
}

.workoutsDivInner{
    height:25vh;
}

.overskrift {
    margin-bottom: 3%;
}

.backButton {
    border-radius: 20px;
    padding: 1% 3%;
    background-color: lightgray;
    width: 30%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 5%;
}

.detailView {
    width: 100%;
}

.sub1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
    height:300px;
    background-position: center;
    position:fixed;
    top:0;
    left:0;
    background-size: cover;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-left: 3%;
    border-radius: 0;
    padding-bottom: 5%;
    background-size: cover;
    /* background-color: rgba(255, 255, 255, 0.05); */
    background-blend-mode: overlay;
    z-index:10;
}

.programDetails {
    z-index:11;
    position:relative;
    margin-top: 40%;
    padding-top: 3%;
    background-color: #FFF;
    width: 100%;
}

.programTitle {
    font-size:2em;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.45);
    padding: 5px 10px;
    border-radius: 20px;
    color:#FFF;
    width: 85%;
    text-align: center;
    margin-right: auto;
}

.stars {
    display: flex;
    justify-content: space-between;
  }
  
  .star {
    font-size: 2rem;
    cursor: pointer;
  }
  
  .star.filled {
    color: gold;
  }
  
  .inputRating {
    margin-top: 20px;
    width: 70%;
    margin: auto;
  }
  
  .inputRating label {
    font-weight: bold;
  }
/* styles.module.css */

.slideForwardEnter {
    transform: translateY(100%);
    opacity: 0;
  }
  .slideForwardEnterActive {
    transform: translateY(0%);
    opacity: 1;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
  }
  .slideForwardExit {
    transform: translateY(0%);
    opacity: 1;
  }
  .slideForwardExitActive {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
  }
  
  .slideBackwardEnter {
    transform: translateY(-100%);
    opacity: 0;
  }
  .slideBackwardEnterActive {
    transform: translateY(0%);
    opacity: 1;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
  }
  .slideBackwardExit {
    transform: translateY(0%);
    opacity: 1;
  }
  .slideBackwardExitActive {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
  }
  
  .submitButton {
    border-radius: 20px;
    background-color: #FFB34B;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20%;
    padding: 5px 0 5px 0 ;
    width: 55%;
  }

  .confirmDialog {
    position: fixed;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10001; /* Sørg for at den vises over modalen */
  }
  
  .confirmDialog p {
    margin-bottom: 20px;
  }
  
  .confirmDialog button {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .confirmDialog button:first-of-type {
    background-color: #FF4B4B;
    color: #fff;
    border: none;
    border-radius: 5px;
  }
  
  .confirmDialog button:last-of-type {
    background-color: #ccc;
    color: #333;
    border: none;
    border-radius: 5px;
  }