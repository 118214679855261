.container {
    margin-bottom: 15vh;
}

.outletDiv {
    margin-top: 14vh;
    overflow-y: auto;
}

.outletDiv::-webkit-scrollbar {
    display: none;
}

/* Skjul scrollbar i Firefox */
.outletDiv {
    scrollbar-width: none; /* Skjuler scrollbar i Firefox */
}
@media (min-width: 720px) {
    .container {
        margin-bottom: 0;
        margin-left:12%;
    }
    .outletDiv {
        margin-top: 0;
    }
}

@media (min-width: 1000px) {
    .container {
        margin-left: 6%;
        background: linear-gradient(to bottom,#fff, #f2f2f2) ;
        background-attachment: fixed;
        min-height: 100vh;
    }

    /* .outletDiv {
        padding-bottom: 10%;
    } */
}