.programItem {
    display: flex;
    flex-direction: column;
    min-width: 70vw;
    max-width: 70vw;
    height: 30vh;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #E4E4E4;
    margin: auto;
    font-style: italic;
    font-weight: 600;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.paddingTop {
    padding-top: 3%;
}

.text{
    margin-bottom: 0;
    max-width: 80%;
    flex-wrap: wrap;
    text-align: center;
    font-size: 1.5em;
    font-weight: 600;
    font-style: italic;
    color:#FFF;
    padding:1% 2%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.4);
}

.programHeader{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width:100%;
    height:70%;
    border-radius: 10px 10px 0 0;
}

.programsDivInner, .workoutsDivInner {
    display: flex;
    overflow-x: auto;
    gap: 3%;
    /* padding-left: 2%; */
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-snap-type: x mandatory;
    padding-right: 16px;
}
.programsDivInner > * {
    scroll-snap-align: center; 
  }
  
  /* .programsDivInner > *:last-child {
    margin-right: 50px;  
  } */
  
.programsDivInner::-webkit-scrollbar, .checkInCardsContainer::-webkit-scrollbar, .workoutsDivInner::-webkit-scrollbar {
    display: none;
}

.programsDiv:hover {
    cursor:pointer;
}

.workoutsDivInner{
    height:25vh;
}

.overskrift {
    margin-bottom: 3%;
}