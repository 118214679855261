.container {
    padding-top: 20vh;
    width: 70vw;
    margin: auto;
}

.hider {
    z-index:1;
    position: fixed;
    top:0;
    background-color: #FFF;
    height:100vh;
    width:100vw;
} 

.logoContainer {
    width: 40%;
    margin:auto;
    padding-top: 10vh;
}

.container2 {
    margin: auto;
    text-align: center;
}

.container2:hover {
    cursor: pointer;
    text-decoration: underline;
}

.restorePurchasesContainer{
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.restoreButton {
    background-color: #FFB34B;
    padding: 5px 25px;
    border-radius: 20px;
}

@media (min-width: 720px) {
    .container {
        width: 30vw;
    }
}