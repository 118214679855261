.container {
    position: fixed;
    bottom:0;
    left:0;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    height:11%;
    background-color: #FFB34B;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: start;
    /* padding-bottom: 5%; */
    box-shadow: 0px -5px 10px rgba(0,0,0,0.1);
    z-index:999;
}

.container2 {
    width: 17%;
    height:80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 2%;
    /* color: #000; */
    color: rgba(0,0,0,0.5);
    text-decoration: none;
    border: 2px solid transparent;
}

.container2:hover {
    cursor: pointer;
    /* opacity: 0.4; */
}

.iconContainer {
    width: 40%;
}

.h2 {
    font-size: 1em;
    margin-top: 2%;
}

.activeLink {
    color: #000;
    font-weight: 900;
}

.iconContainer svg{
    fill:rgba(0,0,0,0.5);
}

.activeLink .iconContainer svg {
    fill: rgba(0, 0, 0, 1); /* Full opacity (svart) når aktiv */
}

@media (min-width: 720px) {

    .container {
        top:0;
        flex-direction: column;
        width: 12%;
        justify-content: space-evenly;
        height:100%;
    }
    .container2 {
        width: 100%;
    }
}

@media (min-width: 1000px) {
    .container {
        width: 6%;
    }

    .activeLink{
        text-decoration: underline;
    }

    .container2{
        height:auto;
        /* color:#000; */
    }

    .iconContainer {
        width: 100%;
    }
}