@import '../../../../styles/variables.scss';

.card {
    background-color: rgba(0,0,0,0.4);
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: #FFF;
    font-weight: 600;
    border:3px solid transparent;
    transition: 0.3s ease border-color, transform 0.3s ease;

    @media(min-width:768px){
        &:hover{
            cursor: pointer;
            border-color: $primary;
            transform: scale(1.02);
        }
    }
}