.variantCard {
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    border-radius: 20px;
    padding:10px 30px;
    width: 90%;
        margin-left: auto;
        margin-right: auto;

    @media (min-width:768px) {
        transition: 0.3s ease background-color;

        &:hover {
            cursor: pointer;
            background-color: rgba(0,0,0,0.15);
        }
    }
}

.clientItem {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;
}

.container2 {
    display: flex;
    flex-direction: column;
    width:100%;
    align-items: center;
}

.cancelButton, .submitButton,.removeClientButton, .removeIngredientButton, .addIngredientButton, .deleteVariantButton, .updateVariantButton {
    color: #000;
    border: none;
    padding: 5px 20px;
    border-radius: 20px;
    background-color: lightcoral;
}

.submitButton, .addIngredientButton, .updateVariantButton {
    background-color: lightgreen;
}

.updateVariantButton:disabled, .submitButton:disabled {

    cursor:not-allowed;
    color: gray;
}

.ingredientInput, .ingredientSearchInput {
    border-radius: 20px;
    border: 1px solid gray;
    padding-left: 5%;
    width:20%;
}

.ingredientSearchInput {

    width: 100%;
}

.addIngredientDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 20px;
}

.ingredientAmountInput {
    border-radius: 20px;
    border: 1px solid gray;
    padding: 5px 20px;
}

.row {
    display: flex;
    width: 80%;
    align-items: center;
    gap: 10px;
}

.ingredientOption {
    box-shadow: 0px 0px 20px rgba(0,0,0,0.25);
    border-radius: 20px;
    padding: 5px 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    width:100%;

    @media (min-width: 768px) {
        margin-top: 10px;
        margin-bottom: 0;
        transition: 0.3s ease background-color;

        &:hover {
            cursor: pointer;
            background-color: rgba(0,0,0,0.15);
        }
    }
}

.filteredIngredients {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.container2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.ingredientAdjustContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.macroSummary {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.modalCustomFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.selected {
    background-color: lightgreen;
}

.modal {
    margin-top: 20%;
    padding-bottom: 35%;

    @media(min-width:768px) {
        margin-top: 0;
        padding-bottom: 0;
    }
}