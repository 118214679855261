.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.input, .textArea {
    border-radius: 20px;
    border: 1px solid gray;
    padding: 5px 10px;
}

.input {
    width: 60%;
}

.imageScrollContainer {
    display: flex;
    width:100%;
    max-width: 100%;
    overflow-x: auto;
    gap: 10px;
    padding: 10px 0;
    scrollbar-width: none; /* Skjuler scrollbar for Firefox */
    padding-left: 2%;
    padding-right: 2%;
}

.select {
    padding: 5px 15px;
    border-radius: 20px;
    border: 1px solid gray;
    color:#000;
}

.imageScrollContainer::-webkit-scrollbar {
    display: none; /* Skjuler scrollbar for Chrome, Safari, Edge */
}

.imageOption {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    cursor: pointer;
}

.imageThumbnail {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    filter: grayscale(0);
    transition: filter 0.3s ease;
}

.imageSelected {
    filter: grayscale(0); /* Farge tilbake for valgt bilde */
}

.imageOption:not(.selected) .imageThumbnail {
    filter: grayscale(0); /* Gråtoner for ikke-valgte bilder */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.checkmark {
    font-size: 24px;
    color: rgb(142, 237, 142);
}

.selectImageButton {
    margin-top: 5px;
    padding: 5px 15px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 20px;
    border: none;
    background-color: lightgreen;
}

.submitButton, .addButton, .removeButton{
    background-color: lightgreen;
    border: none;
    border-radius: 20px;
    padding: 5px 20px;
    color:#000;
}

.removeButton {
    background-color: lightcoral;
}

.ingredientRow {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;

}

.selectedIngredientList {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    min-height: 50px;
}

.selectedIngredientItem {
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3%;
}

.selectedIngredientItem {
    list-style: none;
    margin-bottom: 10px;
}

/* .ingredientRow {
    display: grid;
    grid-template-columns: 1fr auto auto; 
    gap: 10px; 
    align-items: center; 
} */

.ingredientName {
    max-width: 200px; /* Maks bredde for navnet */
    overflow: hidden; /* Skjuler tekst som er for lang */
    text-overflow: ellipsis; /* Legger til "..." for lange navn */
    white-space: nowrap; /* Hindrer tekstbryting */
}

.ingredientSub {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ingredientAmount {
    text-align: right;
    margin-left: auto;
    margin-right: 20px;
}

.removeButton {
    background-color: lightcoral;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    transition: background-color 0.3s;
}

.removeButton:hover {
    background-color: darkred;
}

@media(min-width:1000px) {

    .container {
        width: 95%;
        margin-left:auto;
        margin-right: auto;
        padding-bottom: 8%;
    }
}