.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
    border-radius: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    border-radius: 20px;
    /* box-shadow: 2px 2px 5px rgba(0,0,0,0.3); */
    margin: auto;
    padding-top: 10%;
}

.input {
    margin-top: 3vh;
    border-radius: 20px;
    border: none;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    padding: 2px 10px;
    width: 70%;
}

.fileInput {
    width: 70%;
    margin-bottom: 5vh;
}

.submit {
    margin-bottom: 5vh;
    border: 1px solid transparent;
    border-radius: 20px;
    background-color: #FFB34B;
    padding: 5px 35px;
}

.submit:hover {
    background-color: #FFB34B;
    cursor:pointer;
}

.user {
    font-size: 1.3em;
    font-weight: 600;
}

.overskrift {
    text-align: center;
    font-size: 2em;
    font-weight: 800;
    margin-top: 3%;
    margin-bottom: 15%;
}

@media (min-width: 720px) {

    .overskrift {
        margin-bottom: 5%;
    }

    .submit {
        background-color: #E4E4E4;
    }

}