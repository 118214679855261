.detailContainer {
    /* position: fixed; */
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transition: left 0.5s ease;
    color:#000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5%;
}

/* .showDetail {
    left: 0;
} */

/* .mainContainer {
    width: 100%;
    height:90vh;
    overflow-y: auto;
} */

.mealImage {
    width: 100%;
    height: 40vh;
    background-position: center;
    background-size: cover;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.backIcon {
    height: 26px;
    padding-bottom: 1%;
}
.backButton {
    font-size: 16px;
    /* background: lightgray; */
    /* padding: 8px 16px; */
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: none;
    background: none;
}

.name {
    font-size: 1.8em;
    font-weight: 600;
}

.category {
    margin-bottom: 5%;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 0.7em;
}

.prepInfo {
    width: 95%;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.35);
    border-radius: 20px;
    padding: 25px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -5%;
    background-color: #FFF;
}

.prepChild {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.prepSubHeader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 5px;
}

.iconContainer {
    width: 17%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-bottom: 5%;
}

.prepText {
    padding:0;
    margin:0;
}

.nutritionContainer, .ingredientsContainer, .tabsContainer {
    margin-top: 5%;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.35);
    width: 95%;
}

.nutriChild {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.nutriSub1{
    display:flex;
    flex-direction: column;
    width: 23%;
    align-items: center;
    justify-content: space-between;
}

.nutriSubHeader {
    margin-bottom: 5%;
    margin-top: -2%;
    font-weight: 300;
    font-style: italic;
}

.nutriP {
    font-size: 1.1em;
    font-weight: 600;
}

.nutriS {
    font-size: 0.85em;
}

.ingredientChild {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2%;
    border-bottom: 1px solid lightgray;
    padding-bottom: 2%;
}

.ingredientIconContainer {
    width: 6%;
}

.customTabs {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
    width: 100%;
}

.tabButton {
    flex: 1;
    padding: 10px 0;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: #555;
    transition: color 0.3s ease;
    outline: none;
}

.tabButton:hover {
    color: #FFB34B;
}

.activeTab {
    color: #FFB34B;
    border-bottom: 3px solid #FFB34B;
}

.tabContent {
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 0 0 20px 20px;
    border-top: none;
    margin-top: -1px;
    background-color: #fff;
}

.inputTextArea {
    width: 100%;
    min-height: 100px;
    border-radius: 20px;
    padding: 10px;
}

.inputEditAmounts {
    width: 60px;
    margin-right: 5%;
    border-radius: 20px;
    padding-left: 10px;
    border: 1px solid gray;
}

.inputSubContainer, .ingredientSubContainer {
    display: flex;
    width:30%;
    align-items: center;
    justify-content: end;
}

.ingredientText {
    padding: 0;
    margin: 0;
}

.editInputIcons {
    width: 30%;
    border-radius: 20px;
    border: 1px solid gray;
    padding-left: 10%;
}

.inputName {
    border-radius: 20px;
    border: 1px solid gray;
    padding-left: 5%;
}

.buttonContainer {
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonContainer.editMode {
    justify-content: space-between;
}

.buttonContainer:not(.editMode) {
    justify-content: center;
}

.cancelButton, .editButton, .saveButton, .deleteButton, .removeIngredientButton, .addIngredientButton {
    border-radius: 20px;
    color: #000;
    border: none;
    margin-top: 20px;
    padding: 3px 20px;
}

.cancelButton, .deleteButton, .removeIngredientButton {
    background-color: lightcoral;
}

.removeIngredientButton {
    margin-top: 0;
    margin-left: 20%;
}

.ingredientSearchInput {
    border-radius: 20px;
    border: 1px solid gray;
    padding: 5px 15px;
}

.addIngredientContainer, .addIngredientDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 5%;
}

.ingredientOption {
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
    border-radius: 20px;
    padding: 5px 25px;
    margin-top: 8px;
}

.ingredientAmountInput {
    border: 1px solid gray;
    border-radius: 20px;
    padding: 5px 25px;
    width: 60%;
}

.row {
    gap: 20px;
    display: flex;
    align-items: center;
}

.editButton {
    background-color: lightblue;
}

.saveButton, .addIngredientButton {
    background-color: lightgreen;
}

.backButton {
    color:#000;
}

@media (min-width: 1000px) {
    .detailContainer, .mainContainer {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        /* height:80vh; */
        /* overflow: hidden; */
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }

    .mealImage {
        border-radius: 20px;
    }

    /* .mainContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    } */
    .overflowY {
        overflow-y: auto;
        height:100%;
        flex:1;
    }
    .overflowY::-webkit-scrollbar {
        display: none; /* Scrollbar-bredde */
    }
    
    .overflowY::-webkit-scrollbar-thumb {
        display: none;
    }
    
    .overflowY::-webkit-scrollbar-thumb:hover {
        display: none;
    }

    /* .container{
        display: flex;
        flex-direction: row;
        align-items:start;
        justify-content: space-evenly;
        padding-top: 0;
        overflow-y: hidden;
        height:90vh;
        margin-top: auto;
    } */

    .category {
        padding:0;
        border-radius: 0;
        margin: 0;
    }

    .subMain {
        /* height: 85vh; */
        min-height: 0;
        display: flex;
        overflow:hidden;
        flex:1;
    }
    .desktopHeader {
        /* Fikset topplinje med back knapp + måltidsnavn */
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        margin-top: 5%;
        padding-bottom: 2%;
        /* height: 15%; */
        /* Hvis du vil ha en bunnlinje, background, etc: */
        border-bottom: 1px solid #ccc;
        /* background: #f5f5f5; */
      }
      .headerLeft {
        display: flex;
        align-items: center;
        /* position: absolute;
        left:0; */
        margin-right: auto;
      }
      .headerCenter {
        position:absolute;
        left:50%;
        bottom:0;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    
      /* Tilbake-knappen */
      .backIcon {
        height: 26px;
      }
      .backButton {
        font-size: 16px;
        border: none;
        border-radius: 20px;
        background: none;
        cursor: pointer;
        color: #000;
      }
    
      /* Containeren for de to kolonnene */
      .desktopContent {
        display: flex;
        width: 100%;
        flex: 1; /* fyll opp resten av skjermen */
        min-height: 0; /* viktig ved flex og scrolling */
        max-height: 80vh;
        overflow-y: hidden;
      }
    
      .leftColumn, .rightColumn {
        flex: 1;
        min-height: 0; /* Helt nødvendig for at overflow-y: auto skal fungere i flex child */
        overflow-y: auto;
        /* eventuelt padding om du vil */
        padding: 1rem;
      }

      .prepInfo, .nutritionContainer, .buttonContainer , .addIngredientContainer , .ingredientsContainer, .tabsContainer, .tabContent {
        margin-left: auto;
        margin-right: auto;
      }

      .ingredientOption {
        margin-top: 10px;
        transition: 0.3s ease background-color;
      }
      .ingredientOption:hover {
        cursor: pointer;
        background-color: rgba(0,0,0,0.15);
      }
}
