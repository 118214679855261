/* .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 8999;
    overflow-y: auto;
}

.backdrop.hidden {
    opacity: 0;
  }

.bottomSheet {
    position: fixed;
    bottom:0;
    padding-bottom: 20%;
    left: 0;
    width: 100%;
    max-height: 80vh;
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    transform: translateY(100%);
    transition: transform 0.2s ease-in-out;
    z-index: 9000;
    visibility: hidden;
  }

  .bottomSheet.visible {
    visibility: visible;
  }
  
 
  .bottomSheet.open {
    transform: translateY(0);
  }

  .handle {
    width: 50px;
    height: 5px;
    background-color: #ccc;
    border-radius: 4px;
    margin: 10px auto;
  }
  

  .bottomSheet .content {
    padding: 16px;
    max-height: 80vh;
    min-height: 50vh;
    overflow-y: auto;
  }

.modalOpen {
    overflow: hidden;
  } 
    
  */

  .modalOpen {
    overflow: hidden;
  }
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 8999;
  }
  
  .bottomSheet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 95vh;
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    touch-action: none;
    will-change: transform;
    scrollbar-width: none;
  }

  .bottomSheet::-webkit-scrollbar {
    display: none;
  }

  .handleHeader{
    height:5vh;
    z-index:99999;
  }
  
  .handle {
    width: 50px;
    height: 5px;
    background-color: #ccc;
    border-radius: 4px;
    margin: 10px auto;
    flex-shrink: 0;
    cursor: grab;
  }
  
  .handle:active {
    cursor: grabbing;
  }
  
  .content {
    padding-top: 16px;
    padding-bottom: 20%;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    scrollbar-width: none;
  }

  .content::-webkit-scrollbar {
    display: none;
  }
  
  