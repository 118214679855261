.card {
    min-width: 200px;
    max-width: 200px;
    height: 260px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 20px;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

/* .cardOverlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 0 0 20px 20px;
} */

.cardOverlay {
    color: black;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.text {
    margin-bottom:none;
    font-size: 0.85rem;
}
.subH {
    font-weight: 700;
}

.imageDiv {
    min-width: 200px;
    max-width: 200px;
    height:200px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
}

@media(min-width:768px) {
    .card:hover {
        transform: scale(1.05);
    }
}