.card {
    min-width: 200px;
    height: 260px;
    position: relative;
    cursor: pointer;
    margin: 1rem;
    border-radius: 20px;
    overflow: hidden;
}

.imageDiv {
    min-width: 200px;
    height:200px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
}

.cardOverlay {
    /* background-color: rgba(0, 0, 0, 0.5); */
    color: black;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    /* padding: 10px; */
}

.text {
    margin-bottom:none;
    font-size: 0.85rem;
}
.subH {
    font-weight: 700;
}

.mealName {
    font-size: 1.2rem;
    margin: 0;
}

/* .cardContainer {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: none;
}

.cardContainer::-webkit-scrollbar {
    display: none; /* Skjuler scrollbar for Chrome, Safari, Edge */

.cardContainer {
    display: flex;
    width:100px;
    align-items: center;
    scrollbar-width: none;
}

.cardContainer::-webkit-scrollbar {
    display:none;
}

/* .mobileCard {
    margin-right: 50px;
} */

.modalContainer{
    display: flex;
    flex-direction: column;
    width:100%;
}

.clientList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.removeButton {
    border-radius: 20px;
    padding: 2px 15px;
    background-color: lightcoral;
    border:none;
}

.addButton {
    border-radius: 20px;
    padding: 2px 15px;
    background-color: lightgreen;
    border:none;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
}

.addVariantsButton {
    border-radius: 20px;
    padding: 2px 15px;
    background-color: yellow;
    border:none;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
}

.editButton, .showButton {
    border-radius: 20px;
    padding: 2px 15px;
    background-color: lightblue;
    border:none;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
}

.showButton{
    width:auto;
}

.modal {
    margin-top: 15%;
    margin-bottom: 10%;
    padding-bottom: 25%;
}

.ingredientSub {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.editInput {
    width: 15%;
    border-radius: 20px;
    border: 1px solid gray;
    padding: 2px 10px;
}

.varianterContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clientListContainer {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.select {
    border-radius: 20px;
    padding: 5px 15px;
}

.input, .textArea {
    border-radius: 20px;
    border: 1px solid gray;
    padding: 5px 20px;
    margin-top: 2%;
    margin-bottom: 2%;
}

.ingredient {
    font-weight: 800;
    font-style: italic;
    margin-top: 5%;
}

h5 {
    font-size: 1.5em;
    margin-top: 6%;
}

.variantModal, .createVariantModal {
    background-color: darkgray;
}

.ingredientContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width:1000px) {
    
    .modal {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .container{
        width: 100%;
    }

    .cardContainer, .desktopList {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .card {
        min-height: 200px;
        max-height: 200px;
        min-width: 200px;
        max-width: 200px;
        transition: transform 0.3s ease;
        &:hover {
            transform: scale(1.02);
        }
    }

    .desktopList {
        display: flex;
        flex-direction: column;
      }
      
      .desktopListItem {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0.5rem;
        /* border: 1px solid #ccc; */
        border-radius: 10px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: background-color 0.2s;
        margin-bottom: 8px;
        margin-top: 5px;
      }
      
      .desktopListItem:hover {
        background-color: #f5f5f5;
      }
      
      .thumbnail {
        width:50px !important;
        height:50px !important;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
      }
}