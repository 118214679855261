.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    height:50vh;
    width:90%;
    background-color: #FFB34B;
    border-radius: 30px;
    margin-top: 50%;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    border-radius: 10px;
    border: none;
    padding-left: 5%;
    padding-right: 2%;
}

.button {
    border-radius: 10px;
    border: 1px solid transparent;
    font-size: 1.2em;
    width: 100%;
    margin-top: 3vh;
    background-color: #FFF;
    color:#000;
    margin-bottom: 1%;
}

@media (min-width:768px) {
    .container {
        margin-top: 5%;
        width: 30%;
    }
}