.container{
    width: 100vw;
    display: flex;
    align-items: center;
    margin:auto;
    padding-top: 6vh;
    padding-bottom: 1vh;
    position:fixed;
    top:0;
    left:0;
    z-index: 9;
    background-color: white;
    /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.8); */
}

.header {
    display:none;
}

.imageContainer {
    width: 50%;
    margin:auto;
}

.child1 {
    margin-left: 3%;
}

.child2 {
    margin-right: 3%;
}

.child1, .child2 {
    max-width: 8%;
}

.child1:hover, .child2:hover {
    cursor: pointer;
}

.dropdown {
    position: absolute;
    top: 100px; /* Juster for plassering under Settings-ikonet */
    left: 10px;
    background-color: #f8d7da;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.deleteButton {
    background-color: lightcoral;
    text-align: center;
    border-radius: 20px;
    padding: 5px 15px;
}

@media (min-width: 720px){
    .container{
        width: 88%;
        position:relative;
    }
}

@media (min-width: 1000px){
    .container{
        width: 100%;
        background-color: transparent;
        padding-top: 1vh;
        /* margin-bottom: 1vh; */
        /* border-bottom: 1px solid black; */
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .child1, .child2 {
        max-width: 2%;
    }
    .child1{
        margin-right: auto;
    }

    .child2 {
        margin-left: auto;
    }

    .imageContainer {
        display: none;
    }

    .header{
        display: block;
        text-align: center;
        font-size: 2em;
        font-weight: 900;
        font-style: italic;
        margin-left: auto;
        margin-right: auto;
    }
}