/* Forover-animasjon (navigere til detaljvisning) */
.slide-forward-enter {
    transform: translateX(100%);
  }
  .slide-forward-enter-active {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
  }
  .slide-forward-exit {
    transform: translateX(0%);
  }
  .slide-forward-exit-active {
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;
  }
  
  /* Bakover-animasjon (navigere tilbake til listevisning) */
  .slide-backward-enter {
    transform: translateX(-100%);
  }
  .slide-backward-enter-active {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
  }
  .slide-backward-exit {
    transform: translateX(0%);
  }
  .slide-backward-exit-active {
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
  }