.container {
    z-index:1;
    position: fixed;
    top:0;
    background-color: #FFF;
    height:100vh;
    width:100vw;
} 

.logoContainer {
    width: 40%;
    margin:auto;
    padding-top: 10vh;
}