.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
}

.label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input, .searchInput {
    border-radius: 20px;
    border: 1px solid gray;
    padding-left: 10px;
    padding-top: 5px;
}

.select {
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    color:#000;
}

.button {
    border-radius: 20px;
    background-color: #FFB34B;
    color:#000;
    padding: 5px 25px;
    margin-top: 5%;
}

.button:hover {
    cursor: pointer;
    background-color: #000;
    color:#FFB34B;
}

.ingredientList {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.ingredientItem {
    font-size: 1.2em;
    margin-top: 3%;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    padding: 5px 15px;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    width:95%;
}

.ingredientItem:hover {
    cursor: pointer;
    text-decoration: underline;
}

.closeButton, .deleteButton {
    border-radius: 20px;
    padding: 5px 15px;
    background-color: lightcoral;
    border: 2px solid transparent;
    transition: 0.3s ease border-color;
    color:#000;
}

.closeButton:hover {
    border-color: #000;
    cursor: pointer;
}

@media (min-width: 1000px) {
    /* .container {
        flex-direction: row;
    } */
    .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
}