.parent {
    width: 100%;
    margin:auto;
}

.subChild1 {
    font-weight: 600;
    font-style: italic;
}

.subChild2 {
    font-size: 1.6em;
    font-weight: 600;
}

.child1 {
    width: 97%;
    margin-left: auto;
}

.child2, .child3, .stats{
    margin-top: 5%;
}

.child3, .child2, .stats {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.2);
}

.paddingTop {
    padding-top: 3%;
}