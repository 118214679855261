@import '../../../styles/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;

    @media(min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap:80px;
        min-height: 80vh;
    }
}

.card {
    height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    @media (min-width:768px) {
        transition: transform 0.3s ease, border-color 0.3s ease;
        border: 2px solid transparent;
        &:hover{
            cursor: pointer;
            transform:scale(1.04);
            border-color: $primary;
        }
    }
}

.overlay {
    position: absolute;
    border-radius: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1); /* Juster etter behov */
    z-index: 1;
  }

.content {
    padding: 10px 20px;
    background-color: rgba(255,255,255,0.8);
    border-radius: inherit;
    backdrop-filter: blur(5px);         /* Blureffekt */
    -webkit-backdrop-filter: blur(5px);
}